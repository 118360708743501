var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-1"},[(_vm.option == 'start')?_c('section',[_c('v-row',{staticClass:"mt-12"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"card-start"},[_c('p',{staticClass:"text-h4 my-4"},[_vm._v(" Olá, vou te ajudar a configurar as salas e turmas para a simulação. ")]),_c('p',{staticClass:"text-subtitle-2"},[_vm._v(" Primeiro, nomeie esta nova entrada: ")]),_c('v-text-field',{attrs:{"outlined":"","label":"Nome"},on:{"input":_vm.handleChange},model:{value:(_vm.nameI),callback:function ($$v) {_vm.nameI=$$v},expression:"nameI"}}),_c('button',{staticClass:"btn blue",on:{"click":_vm.handleStart}},[_vm._v(" INICIAR ")])],1)]),(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mx-auto"},[_c('v-img',{staticClass:"img mt-n12 mb-16",attrs:{"src":require("../../assets/setup-init.svg"),"alt":"test"}})],1),_c('ProgressDisplay',{staticClass:"mt-5",attrs:{"steps":5,"where":1,"discription":[
            'Início',
            'Editar Salas',
            'Editar Turmas',
            'Opções',
            'Simular'
          ]}})],1):_vm._e()],1)],1):_vm._e(),(_vm.option == 'end')?_c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"card-end grey mt-11 lighten-4"},[_c('apexchart',{attrs:{"type":"pie","options":_vm.chartOptions,"series":[_vm.rooms, _vm.classes - _vm.preAloc, _vm.preAloc]}}),_c('div',{staticClass:"btn-save mt-4",on:{"click":_vm.saveProgress}},[_c('p',{staticClass:"float-left mt-4 ml-6"},[_vm._v("SALVAR")]),_c('v-icon',{staticClass:"float-right mt-4 mr-6 icon"},[_vm._v("mdi-content-save-outline")])],1),_c('button',{staticClass:"btn blue mt-4",on:{"click":_vm.simular}},[_vm._v(" SIMULAR ")])],1)]),(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mx-auto"},[_c('v-img',{staticClass:"img",attrs:{"src":require("../../assets/Ok-cuate.svg"),"alt":"test"}})],1),_c('ProgressDisplay',{attrs:{"steps":5,"where":5,"discription":[
            'Início',
            'Editar Salas',
            'Editar Turmas',
            'Opções',
            'Simular'
          ]}})],1):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }