<template>
  <section class="clear">
    <v-row v-if="!component">
      <v-col cols="12" md="2">
        <v-select
          dense
          v-model="input.nivelDeEnsino"
          :items="select.nivelDeEnsino"
          label="Nível de Ensino"
          item-text="n"
          item-value="v"
          outlined
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          dense
          v-model="input.unidade"
          :items="departamentos"
          item-text="name"
          item-value="id"
          label="Unidade"
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          type="number"
          label="Ano"
          v-model="input.ano"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          dense
          v-model="input.periodo"
          :items="[1, 2, 3, 4]"
          label="Período"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn v-if="loading" color="blue">
          <v-progress-circular
            indeterminate
            color="white"
            size="20"
          ></v-progress-circular>
        </v-btn>

        <v-btn v-if="!disableSearch1" color="grey" dark>buscar</v-btn>

        <v-btn
          v-else
          @click="handleSearch"
          :loading="loading"
          :disabled="loading"
          color="blue"
          dark
          >buscar</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          label="Código"
          v-model="input.codigo"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          dense
          outlined
          label="Nome da disciplina"
          v-model="input.nome"
        >
        </v-text-field
      ></v-col>
      <!-- <v-col cols="12" md="4">
        <v-select
          dense
          v-model="input.unidade"
          :items="departamentos"
          item-text="name"
          item-value="id"
          label="Unidade"
          outlined
        ></v-select>
      </v-col> -->
      <v-col cols="12" md="2">
        <v-text-field
          dense
          outlined
          type="number"
          label="Ano"
          hint="*opcional"
          persistent-hint
          v-model="input.ano"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-select
          dense
          v-model="input.periodo"
          :items="[1, 2, 3, 4]"
          label="Período"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn v-if="loading" color="blue">
          <v-progress-circular
            indeterminate
            color="white"
            size="20"
          ></v-progress-circular>
        </v-btn>
        <v-btn v-if="!disableSearch2" color="grey" dark>buscar</v-btn>
        <v-btn
          v-else
          @click="handleSearch"
          :loading="loading"
          :disabled="loading"
          color="blue"
          dark
          >buscar</v-btn
        >
      </v-col>
    </v-row>

    <text-divider
      class="pointer"
      button
      @click.native="component = !component"
      :text="
        component
          ? 'trocar para buscar por departamento'
          : 'trocar para buscar por componente'
      "
      :append-icon="'mdi-magnify'"
    />

    <p v-if="show_no_data" class="mt-8">
      A consulta não retornou nenhum dado. Verifique os campos e tente
      novamente..
    </p>
  </section>
</template>

<script>
import TextDivider from "@/components/TextDivider.vue";
import { departaments } from "@/api/util";
import { findClasses } from "@/api/sigaa";

export default {
  name: "SearchClassesInput",
  components: {
    TextDivider
  },
  data() {
    return {
      expand: false,
      loading: false,
      component: false,
      input: {
        nivelDeEnsino: "G",
        unidade: null,
        ano: 2025,
        periodo: 1,
        codigo: "",
        nome: ""
      },
      show_no_data: false,
      departamentos: [],
      select: {
        nivelDeEnsino: [
          { n: "Graduação", v: "G" },
          { n: "Mestrado", v: "M" },
          { n: "Doutorado", v: "D" }
        ],
        input: {
          nivelDeEnsino: true,
          unidade: true,
          ano: true,
          periodo: true,
          codigo: false,
          nome: false
        }
      }
    };
  },
  computed: {
    disableSearch1() {
      try {
        return (
          Number(this.input.ano) > 2000 &&
          Number(this.input.ano) <= new Date().getFullYear() + 3 &&
          this.input.unidade != null
        );
      } catch {
        return false;
      }
    },
    disableSearch2() {
      return this.input.codigo != "" || this.input.nome != "";
    }
  },
  methods: {
    resetSelectInput(v1, v2, v3, v4, v5, v6) {
      this.select.input = {
        nivelDeEnsino: v1,
        unidade: v2,
        ano: v3,
        periodo: v4,
        codigo: v5,
        nome: v6
      };
    },
    getComponentParams() {
      this.resetSelectInput(
        false,
        false,
        false,
        false,
        this.input.codigo != "",
        this.input.nome != ""
      );

      let params = {
        //ano: "ano",
        //periodo: "periodo",
        //unidade: "unidade",
        codigo: "codigo",
        nome: "nome"
      };

      let value = Object.entries(this.select.input)
        .filter(e => e[1])
        .map(e => `${params[e[0]]}=${this.input[e[0]]}`);

      return `?${value.join("&")}`;
    },
    getParams() {
      this.resetSelectInput(true, true, true, true, false, false);

      let params = {
        nivelDeEnsino: "nivel",
        unidade: "unidade",
        ano: "ano",
        periodo: "periodo"
      };

      let value = Object.entries(this.select.input)
        .filter(e => e[1])
        .map(e => `${params[e[0]]}=${this.input[e[0]]}`);

      return `?${value.join("&")}`;
    },
    handleSearch() {
      this.show_no_data = false;
      this.loading = true;

      this.$emit("start-request", false, []);

      let params = this.component
        ? this.getComponentParams()
        : this.getParams();

      findClasses(params)
        .then(res => {
          if (res == "not found") {
            this.show_no_data = true;
            this.$emit("finish-request", false, []);
          } else {
            if (this.component && this.input.ano != "") {
              let filter = res.filter(
                el =>
                  el.periodo ==
                  `${this.input.ano}.${this.input.periodo}`
              );
              this.show_no_data = filter.length == 0;
              this.$emit("finish-request", filter.length > 0, filter);
            } else {
              this.show_no_data = res.length == 0;
              this.$emit("finish-request", true, res);
            }
          }
        })
        .catch(err => {
          this.$emit("finish-request", false, []);
          this.show_no_data = true;
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.departamentos = departaments();
  }
};
</script>

<style scoped>
.clear {
  clear: both;
  width: 100%;
}

.pointer {
  cursor: pointer;
}
</style>
