<template>
  <div class="main-1">
    <section v-if="option == 'start'">
      <v-row class="mt-12">
        <v-col cols="12" md="6">
          <div class="card-start">
            <p class="text-h4 my-4">
              Olá, vou te ajudar a configurar as salas e turmas para a
              simulação.
            </p>
            <p class="text-subtitle-2">
              Primeiro, nomeie esta nova entrada:
            </p>
            <v-text-field
              outlined
              label="Nome"
              v-model="nameI"
              @input="handleChange"
            ></v-text-field>
            <button @click="handleStart" class="btn blue">
              INICIAR
            </button>
          </div>
        </v-col>
        <v-col v-if="!isMobile" cols="12" md="6">
          <div class="mx-auto">
            <v-img
              class="img mt-n12 mb-16"
              src="../../assets/setup-init.svg"
              alt="test"
            />
          </div>
          <ProgressDisplay
            class="mt-5"
            :steps="5"
            :where="1"
            :discription="[
              'Início',
              'Editar Salas',
              'Editar Turmas',
              'Opções',
              'Simular'
            ]"
          />
        </v-col>
      </v-row>
    </section>

    <section v-if="option == 'end'">
      <v-row>
        <v-col cols="12" md="6">
          <div class="card-end grey mt-11 lighten-4">
            <apexchart
              type="pie"
              :options="chartOptions"
              :series="[rooms, classes - preAloc, preAloc]"
            ></apexchart>

            <div @click="saveProgress" class="btn-save mt-4">
              <p class="float-left mt-4 ml-6">SALVAR</p>
              <v-icon class="float-right mt-4 mr-6 icon"
                >mdi-content-save-outline</v-icon
              >
            </div>
            <button @click="simular" class="btn blue mt-4">
              SIMULAR
            </button>
          </div>
        </v-col>
        <v-col v-if="!isMobile" cols="12" md="6">
          <div class="mx-auto">
            <v-img
              class="img"
              src="../../assets/Ok-cuate.svg"
              alt="test"
            />
          </div>
          <ProgressDisplay
            :steps="5"
            :where="5"
            :discription="[
              'Início',
              'Editar Salas',
              'Editar Turmas',
              'Opções',
              'Simular'
            ]"
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import ProgressDisplay from "../ProgressDisplay.vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  name: "PaasSimuladorDisplay",
  components: {
    ProgressDisplay
  },
  props: {
    option: String,
    start: Function,
    rooms: Number,
    classes: Number,
    preAloc: Number,
    simular: Function,
    saveProgress: Function,
    name: String,
    newName: Function
  },
  data() {
    return {
      nameI: this.$props.name,
      chartOptions: {
        chart: {
          width: "100%",
          type: "pie"
        },
        labels: [
          "Salas",
          "Turmas Para Alocação",
          "Turmas Pré Alocadas"
        ],
        theme: {
          monochrome: {
            enabled: true
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          }
        },
        legend: {
          show: false
        }
      }
    };
  },
  watch: {
    name(newName) {
      this.nameI = newName;
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    }
  },
  methods: {
    handleChange(e) {
      this.newName(e);
    },
    handleStart() {
      this.newName(this.nameI);
      this.start();
    }
  }
};
</script>

<style scoped>
.main-1 {
  margin-top: 0;
}

.card-start {
  max-width: 70%;
}

.img {
  max-width: 50%;
  margin: 0 auto 50px auto;
}

.card-end {
  margin: 0 auto 0 auto;
  border-radius: 5px;
  width: 400px;
  padding: 40px;
}

.card-detail {
  border-radius: 5px;
  width: 150px;
  height: 150px;
  text-align: center;
}

.card-detail p {
  margin-top: 20px;
  color: #eeeeee;
  font-size: 30px;
}

.btn {
  color: white;
  width: 100%;
  height: 57px;
  border-radius: 5px;
}

.btn-save {
  cursor: pointer;
  color: white;
  background: linear-gradient(110deg, #64b5f6 75%, white 0%);
  width: 100%;
  height: 57px;
  border-radius: 5px;
}

.icon {
  color: #64b5f6;
}

@media only screen and (max-width: 570px) {
  .card-start {
    max-width: 100%;
    padding: 0 10px;
  }

  .card-end {
    margin-top: 20px;
    width: 100%;
    clear: both;
  }

  .card-detail {
    border-radius: 5px;
    width: 100px;
    height: 100px;
    text-align: center;
  }

  .card-detail p {
    margin-top: 20px;
    color: #eeeeee;
    font-size: 15px;
  }
}
</style>
