import axios from "axios";
import store from "@/store/vuex";

const server_uri = process.env.VUE_APP_SERVER_URI;


export const findClasses = async (nivel, unidade, ano, periodo, codigo, nome) => {
  try {

    const res = await axios.get(`${server_uri}/paas/getSigaaTurmas` +
      `?unidade=${unidade}&ano=${ano}&periodo=${periodo}&nivel=${nivel}&codigo=${codigo}&nome=${nome}`,
      {
        headers: { "Content-Type": "application/json" }
      });

    return res.data;

  } catch (e) {
    console.log("error: ", e);
    return 'not found'

  }
}

export const getPublicSolution = async (centro) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server_uri}/db/public/paas?centro=${centro}`,
        {
          headers: { "Content-Type": "application/json" }
        }).then((res) => {
          resolve(res.data);
        }).catch(e => {
          console.log("error: ", e);
          reject(e)
        })
  });
};

export const solverPaas = async (PaasData) => {
  return new Promise((resolve, reject) => {
    // Ask server for solution hash

    axios
      .post(
        `${server_uri}/solve/paas`,
        { paasData: PaasData },
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then(res => {
        // Commit hash and status to store

        console.log(res.data)

        store.dispatch("addToHistoricoPAAS", {
          hash: res.data.hash,
          nome: PaasData.name,
          status: 'queue',
          numRooms: PaasData.rooms.length,
          numClasses: PaasData.classes.length
        });

        resolve(true);
      })
      .catch(e => {
        console.log("error: ", e);
        reject(false);
      });
  });
};

export const validateInput = (input) => {

  let error = '';
  let warning = '';

  //validate number of classes and rooms
  if (input.rooms.length < 4)
    error += 'Número invalido de salas. Adicione ao menos 4 salas.\n';
  if (input.classes.length < 4)
    error += 'Número invalido de turmas. Adicione ao menos 4 turmas.\n';


  let pre_aloc_count = 0;
  let pre_aloc = [];

  input.classes.forEach((classObj) => {

    let name = `[${classObj.codigo}-${classObj.turma}] ${classObj.nome}`;

    //Validate class time format
    let time = classObj.horario;
    time.split(' ').forEach((e) => {
      if (e.length != 0 && !/([2-7])+[MTN]([1-6])+/.test(e)) {
        error += `${name}, possui um formato de horário inválido. (exemplo para turmas com mais horários: 24M23 35T23).\n`;
      }

    })


    if (classObj.pre_alocacao != undefined && classObj.pre_alocacao.length > 0) {

      //verificar se está pre alocada a uma sala cuja preferencia seja 0;

      pre_aloc_count++;
      pre_aloc.push({
        name,
        sala: classObj.pre_alocacao,
        horario: classObj.horario.toLocaleUpperCase().split(" "),
      });


      let idx_room = input.rooms.findIndex(e => `${e.bloco} ${e.nome}`.toLocaleLowerCase() == classObj.pre_alocacao.toLocaleLowerCase());
      if (idx_room != -1) {

        //verify if number of room capacity allow class allocation
        if (Number(input.rooms[idx_room].capacidade + ((Number(input.rooms[idx_room].capacidade) * 15) / 100)) < Number(classObj.alunos)) {
          warning += `${name}, está pré alocada a uma sala com capacidade menor que o número de alunos. \n`;

        }

        //verify if class is pre allocated to a acessible room
        if (classObj.pcd && !input.rooms[idx_room].acessivel)
          error += `${name}, está pré alocada a uma sala sem acessibilidade. \n`;
      } else {
        //verify if is pre allocated to an actual room
        error += `${name}, está pré alocada a uma sala inexistente. \n`;
      }

    }
  })

  //validate number of pre-aloc
  if (pre_aloc_count == input.classes.length)
    warning += 'Todas as turmas possuem uma pre alocação.\n';

  //validate time conflict
  for (let i = 0; i < pre_aloc.length; ++i) {

    let el = JSON.parse(JSON.stringify(pre_aloc[0]));
    pre_aloc.shift();

    let found = pre_aloc.filter(e => e.sala == el.sala)

    if (found.length > 0) {
      //there are 2 or more classes pre allocated in same room
      el.horario.forEach(t_c => {
        found.forEach(t_f => {
          t_f.horario.forEach(t_f_t => {
            //both same period
            if (t_c.includes("M") && t_f_t.includes("M")) {
              let A = t_c.split("M")
              let B = t_f_t.split("M")
              let iqual = false;
              for (let i = 0; i < A[0].length; i++) {
                //both same day
                if (B[0].includes(A[0][i])) {
                  iqual = true;
                  break
                }
              }
              if (iqual) {
                for (let j = 0; j < A[1].length; j++) {
                  //both same time
                  if (B[1].includes(A[1][j])) {
                    error += `Há um choque de horário nas turmas pré alocadas: ${el.name} e ${t_f.name} `
                    break
                  }
                }
              }
            }
            //both same period
            else if (t_c.includes("T") && t_f_t.includes("T")) {
              let A = t_c.split("T")
              let B = t_f_t.split("T")
              let iqual = false;
              for (let i = 0; i < A[0].length; i++) {
                //both same day
                if (B[0].includes(A[0][i])) {
                  iqual = true;
                  break
                }
              }
              if (iqual) {
                for (let j = 0; j < A[1].length; j++) {
                  //both same time
                  if (B[1].includes(A[1][j])) {
                    error += `Há um choque de horário nas turmas pré alocadas: ${el.name} e ${t_f.name} `
                    break
                  }
                }
              }
            }
            //both same period
            else if (t_c.includes("N") && t_f_t.includes("N")) {
              let A = t_c.split("N")
              let B = t_f_t.split("N")
              let iqual = false;
              for (let i = 0; i < A[0].length; i++) {
                //both same day
                if (B[0].includes(A[0][i])) {
                  iqual = true;
                  break
                }
              }
              if (iqual) {
                for (let j = 0; j < A[1].length; j++) {
                  //both same time
                  if (B[1].includes(A[1][j])) {
                    error += `Há um choque de horário nas turmas pré alocadas: ${el.name} e ${t_f.name} `
                    break
                  }
                }
              }
            }
          })
        })
      });
    }
  }

  if (warning.length > 0) {
    warning = 'Atenção!\n\n' + warning + '\nApesar disto, você ainda poderá realizar a simulação. clique em ok para continuar ou cancele para alterar a configuração.'
  }

  return { error, warning };
}

export const validateRooms = (rooms, oldValue) => {

  let newValue = JSON.parse(JSON.stringify(oldValue));

  rooms.forEach(room => {
    let idx = newValue.findIndex(e => `${e.bloco} ${e.nome}`.toLocaleLowerCase() == `${room.bloco} ${room.nome}`.toLocaleLowerCase());
    if (idx == -1)
      newValue.push(room);
    else {
      newValue.splice(idx, 1);
      newValue.push(room);
    }
  });

  return JSON.parse(JSON.stringify(newValue));

}

export const validateClasses = (classes, oldValue) => {

  let newValue = JSON.parse(JSON.stringify(oldValue));

  for (let classObj of classes) {
    let idx = newValue.findIndex(e => `${e.codigo}-${e.turma}`.toLocaleLowerCase() == `${classObj.codigo}-${classObj.turma}`.toLocaleLowerCase());
    if (idx == -1)
      newValue.push(classObj);
    else {
      newValue.splice(idx, 1);
      newValue.push(classObj);
    }
  }

  return JSON.parse(JSON.stringify(newValue));

}

export const handleEditSolution = (plan, name, pre_aloc) => {

  let input = {
    name: `(Editando) ${name.slice(0, 20)}`,
    roomTypes: [],
    classes: [],
    rooms: [],
    departaments: []
  };

  plan.solution.forEach(room => {


    if (input.roomTypes.indexOf(room.tipo) == -1 && room.tipo != undefined && room.tipo != 'indefinido' && room.tipo != null ) {
      input.roomTypes.push(room.tipo);
    }
      

    room.classes.forEach(classObj => {
      if (input.departaments.indexOf(classObj.departamento) == -1)
        input.departaments.push(classObj.departamento);

      classObj.alunos = Number(classObj.alunos);

      let found = pre_aloc.findIndex((e) => e.idx == `${classObj.codigo}-${classObj.turma}`)

      if (`${room.bloco} ${room.nome}` != 'Não Alocado')
        classObj.pre_alocacao = found != -1 ? pre_aloc[found].sala_alocada : '';

      input.classes.push(classObj);
    })

    delete room.classes;

    if (`${room.bloco} ${room.nome}` != 'Não Alocado') {
      input.rooms.push(room);
    }

  });

  const validade_input = verifyPreferences(input);

  store.dispatch("setPaasInputPlaceholder", { id: '', ...validade_input });

}


export const verifyPreferences = (input) => {

  //update all classes to include all roomTypes preferences
  input.classes.forEach(e => {

      let prefNames = e.preferencias.map(el => el.name)

      input.roomTypes.forEach(roomType => {
          if (prefNames.indexOf(roomType) == -1) {
              e.preferencias.push({
                  name: roomType,
                  value: 1,
              });
          }
      })
  });


  input.rooms.forEach(e => {

    let prefNames = e.preferencias.map(el => el.name)

    input.departaments.forEach(depto => {
        if (prefNames.indexOf(depto) == -1) {
            e.preferencias.push({
                name: depto,
                value: 1,
            })
        }
    })

  });

  return input

}

export const handleReuseSolutionDirect = (plan, name) => {

  let input = {
    name: `(Editando) ${name.slice(0, 20)}`,
    roomTypes: [],
    classes: [],
    rooms: [],
    departaments: []
  };

  for (let room of plan.solution) {

    if (input.roomTypes.indexOf(room.tipo) == -1)
      input.roomTypes.push(room.tipo);

    for (let classObj of room.classes) {

      if (input.departaments.indexOf(classObj.departamento) == -1)
        input.departaments.push(classObj.departamento);

      classObj.alunos = Number(classObj.alunos);

      input.classes.push(classObj);

    }

    delete room.classes;

    input.rooms.push(room);

  }

  store.dispatch("setPaasInputPlaceholder", { id: '', ...input });

}

export const handleNewSolutionRequests = (classes, rooms) => {

  let input = {
    name: 'Nova entrada com turmas solicitadas',
    rooms: [],
    roomTypes: [],
    classes: [],
    departaments: []
  }

  

  for (let classObj of classes) {

    delete classObj.hash;
    delete classObj.paas_classes_request_id;
    delete classObj.id;
    delete classObj.author;
    delete classObj.requestName;

    classObj.preferencias = rooms.length == 0 ? [] : classObj.preferencias != null ? classObj.preferencias.length > 0 ? classObj.preferencias : []: [];
    classObj.pre_alocacao = '';
    classObj.idx = `${classObj.codigo}-${classObj.turma}`;

    if (input.departaments.indexOf(classObj.departamento) == -1)
      input.departaments.push(classObj.departamento);

    input.classes.push(classObj);

  }

  if (rooms.length > 0) {

    input.rooms = rooms;
    //verify if there is a new roomType
    rooms.forEach(e => {
      let idx = input.roomTypes.indexOf(e.tipo);
      if (idx < 0) {
        //add if it is new
        input.roomTypes.push(e.tipo);
      }

      input.departaments.forEach(el => {
        let getIdx = e.preferencias.findIndex(element => element.name == el);
        if(getIdx == -1)
          e.preferencias.push({name: el, value: 1});
      })
    });
  }

  store.dispatch("setPaasInputPlaceholder", { id: '', ...input });
}


export const validateEditSolution = (input) => {

  let error = '';

  let pre_aloc_count = 0;
  let pre_aloc = [];

  input.classes.forEach((classObj) => {

    let name = `[${classObj.codigo}-${classObj.turma}] ${classObj.nome}`;

    //Validate class time format
    let time = classObj.horario;
    time.split(' ').forEach((e) => {
      if (e.length != 0 && !/([2-7])+[MTN]([1-6])+/.test(e)) {
        error += `${name}, possui um formato de horário inválido. (exemplo para turmas com mais horários: 24M23 35T23).\n`;
      }

    })


    if (classObj.pre_alocacao != undefined && classObj.pre_alocacao.length > 0) {

      //verificar se está pre alocada a uma sala cuja preferencia seja 0;

      pre_aloc_count++;
      pre_aloc.push({
        name,
        sala: classObj.pre_alocacao,
        horario: classObj.horario.toLocaleUpperCase().split(" "),
      });


      let idx_room = input.rooms.findIndex(e => `${e.bloco} ${e.nome}`.toLocaleLowerCase() == classObj.pre_alocacao.toLocaleLowerCase());
      if (idx_room == -1) {
        //verify if is pre allocated to an actual room
        error += `${name}, está alocada a uma sala inexistente. \n`;
      }

    }

    if (classObj.pre_alocacao == '')
      error += `${name}, não está alocada a uma sala. \n`;
  })

  //validate number of pre-alloc
  if (pre_aloc_count != input.classes.length)
    error += 'Há turmas sem alocação.\n';

  //validate time conflict
  for (let i = 0; i < pre_aloc.length; ++i) {

    let el = JSON.parse(JSON.stringify(pre_aloc[0]));
    pre_aloc.shift();

    let found = pre_aloc.filter(e => e.sala == el.sala)

    if (found.length > 0) {
      //there are 2 or more classes pre allocated in same room
      el.horario.forEach(t_c => {
        found.forEach(t_f => {
          t_f.horario.forEach(t_f_t => {
            //both same period
            if (t_c.includes("M") && t_f_t.includes("M")) {
              let A = t_c.split("M")
              let B = t_f_t.split("M")
              let iqual = false;
              for (let i = 0; i < A[0].length; i++) {
                //both same day
                if (B[0].includes(A[0][i])) {
                  iqual = true;
                  break
                }
              }
              if (iqual) {
                for (let j = 0; j < A[1].length; j++) {
                  //both same time
                  if (B[1].includes(A[1][j])) {
                    error += `Há um choque de horário nas turmas alocadas: ${el.name} e ${t_f.name} `
                    break
                  }
                }
              }
            }
            //both same period
            else if (t_c.includes("T") && t_f_t.includes("T")) {
              let A = t_c.split("T")
              let B = t_f_t.split("T")
              let iqual = false;
              for (let i = 0; i < A[0].length; i++) {
                //both same day
                if (B[0].includes(A[0][i])) {
                  iqual = true;
                  break
                }
              }
              if (iqual) {
                for (let j = 0; j < A[1].length; j++) {
                  //both same time
                  if (B[1].includes(A[1][j])) {
                    error += `Há um choque de horário nas turmas pré alocadas: ${el.name} e ${t_f.name} `
                    break
                  }
                }
              }
            }
            //both same period
            else if (t_c.includes("N") && t_f_t.includes("N")) {
              let A = t_c.split("N")
              let B = t_f_t.split("N")
              let iqual = false;
              for (let i = 0; i < A[0].length; i++) {
                //both same day
                if (B[0].includes(A[0][i])) {
                  iqual = true;
                  break
                }
              }
              if (iqual) {
                for (let j = 0; j < A[1].length; j++) {
                  //both same time
                  if (B[1].includes(A[1][j])) {
                    error += `Há um choque de horário nas turmas pré alocadas: ${el.name} e ${t_f.name} `
                    break
                  }
                }
              }
            }
          })
        })
      });
    }
  }

  return error;
}